<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Menu.FileTranscription") }}</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <p>
            Los ficheros de transcripción y subtítulos se mantendrán en el
            servidor durante una semana. Se pueden transcribir ficheros de audio
            en formato mp3 y ogg, codificados en 16bits y con frecuencia de
            muestreo de 8-16KHz. Se puede codificar un archivo en este formato
            usando ffmpeg, por ejemplo con la instrucción:
          </p>
          <pre>ffmpeg -i 3_orig.mp3 -c:a libopus -b:a 16k salida.ogg</pre>
        </div>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>Configuración</h2>
          <div class="form-group row mt-4">
            <label for="title" class="col-2 col-form-label text-center">{{
              $t("Copla.FileTranscription.Name")
            }}</label>
            <div class="col-10">
              <input
                type="text"
                class="form-control"
                name="title"
                id="title"
                min="1"
                v-model="title"
              />
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="email" class="col-2 col-form-label text-center">{{
              $t("Copla.FileTranscription.Email")
            }}</label>
            <div class="col-10">
              <input
                type="email"
                class="form-control"
                name="email"
                id="email"
                min="1"
                v-model="email"
              />
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="server" class="col-2 col-form-label">{{
              $t("Copla.FileTranscription.Language")
            }}</label>
            <div class="col-10">
              <select v-model="language" class="custom-select">
                <option
                  v-bind:value="id"
                  v-for="(value, id) in languages"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label for="lines" class="mt-4 col-2 col-form-label text-center">{{
              $t("Copla.MercurioSubtitles.Format.Lines")
            }}</label>
            <div class="col-4 mt-4 ">
              <input
                type="number"
                class="form-control"
                name="lines"
                id="lines"
                min="1"
                v-model="lines"
              />
            </div>
            <label
              for="characters"
              class="mt-4 col-2 col-form-label text-center"
              >{{ $t("Copla.MercurioSubtitles.Format.Characters") }}</label
            >
            <div class="col-4 mt-4">
              <input
                type="number"
                class="form-control"
                name="characters"
                id="characters"
                min="1"
                max="120"
                v-model="characters"
              />
            </div>
            <div class="mt-4 col-3">
              {{ $t("Copla.FileTranscription.Profanity") }}
            </div>
            <div class="mt-4 col-9">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="profanity"
                  id="profanity0"
                  value="Masked"
                  checked
                  v-model="profanity"
                />
                <label class="form-check-label" for="profanity0">
                  {{ $t("Copla.FileTranscription.Profanities.Masked") }}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="profanity"
                  id="profanity1"
                  value="Removed"
                  v-model="profanity"
                />
                <label class="form-check-label" for="profanity1">
                  {{ $t("Copla.FileTranscription.Profanities.Removed") }}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="profanity"
                  id="profanity2"
                  value="None"
                  v-model="profanity"
                />
                <label class="form-check-label" for="profanity2">
                  {{ $t("Copla.FileTranscription.Profanities.Raw") }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="border rounded secciones mt-4">
          <div class="form-group row">
            <label for="file" class="col-3 col-form-label text-center">{{
              $t("Copla.FileTranscription.File")
            }}</label>
            <div class="col-9">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".mp3,.ogg"
                required
              />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="text-center">
              <button
                type="button"
                id="comenzar"
                name="comenzar"
                class="btn btn-success"
                v-if="file != '' && file !== undefined"
                v-on:click="upload"
              >
                {{ btnText }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4" id="tableFiles">
          <table class="table table-sm table-striped table-dark">
            <caption>
              Actualizado:
              {{
                update_table
              }}
            </caption>
            <thead>
              <tr>
                <th>Fecha creación</th>
                <th>Fecha estado</th>
                <th>Nombre</th>
                <th>Lenguaje</th>
                <th>
                  Estado<span class="reload" v-on:click="getStatus()"
                    >&#x21bb;</span
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, i) in reverseServerFiles" :key="i" :id="i">
                <td>{{ datetimeString(file.createdDateTime) }}</td>
                <td>{{ datetimeString(file.lastActionDateTime) }}</td>
                <td>{{ file.displayName }}</td>
                <td>{{ file.locale }}</td>
                <td>
                  <button
                    type="button"
                    v-on:click="getFiles(status_buttons[i].id)"
                    :class="status_buttons[i].style"
                  >
                    {{ status_buttons[i].text }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AzureAPI from "../js/AzureAPI";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
import MainMenu from "../components/MainMenu";
export default {
  name: "FileTranscription",
  components: {
    MainMenu
  },
  data() {
    return {
      showModal: false,
      transcription: "",
      languages: this.$t("Copla.Transcription.Languages"),
      profanity: "None",
      title: "",
      email: "",
      file: "",
      language: "es-ES",
      btnText: this.$t("Copla.Transcription.Button-On"),
      transcribing: "",
      serverFiles: [],
      base_url: process.env.VUE_APP_BASE_URL,
      update_table: 0,
      downloading: 0,
      json_file: "",
      lines: 2,
      fileDownload: "transcription.txt",
      characters: 37,
      azureAPI: new AzureAPI({
        key: process.env.VUE_APP_AZURE_KEY,
        project: process.env.VUE_APP_AZURE_PROJECT,
        location: process.env.VUE_APP_AZURE_LOCATION
      })
    };
  },
  computed: {
    dataAjax: function() {
      const data = {
        _url: this.json_file,
        _lines: this.lines,
        _characters: this.characters
      };
      return data;
    },
    reverseServerFiles() {
      return this.serverFiles.slice().reverse();
    },
    status_buttons() {
      let self = this;
      let data = [];
      self.reverseServerFiles.forEach(function(value) {
        let newButton = { text: "", style: "", id: self.extractId(value.self) };
        switch (value.status) {
          case "NotStarted":
            newButton.text = "Sin comenzar";
            newButton.style = "btn-primary no-click";
            break;
          case "Running":
            newButton.text = "Transcribiendo";
            newButton.style = "btn-warning no-click";
            break;
          case "Succeeded":
            newButton.text = "Disponible";
            newButton.style = "btn-success";
            break;
          case "Failed":
            newButton.text = "Fallo";
            newButton.style = "btn-danger no-click";
            break;
        }
        data.push(newButton);
      });
      return data;
    }
  },
  mounted() {
    this.getStatus();
  },
  watch: {},
  methods: {
    onModalClose: function() {
      this.showModal = false;
    },
    download: function(content) {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(content)
      );
      element.setAttribute("download", this.fileDownload);
      element.style.display = "none";
      element.click();
      this.onModalClose();
      this.downloading = 0;
    },
    downloadSRT: function() {
      let self = this;
      self.downloading = 1;
      self.fileDownload = "subtitles.srt";
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getAzure(self.dataAjax, "srt").then(response => {
        if (response.code == "200") {
          self.download(response.data);
        } else {
          self.messages = response.data;
        }
      });
    },
    downloadTranscription: function() {
      let self = this;
      self.downloading = 1;
      self.fileDownload = "transcription.txt";
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getAzure(self.dataAjax, "transcription").then(response => {
        if (response.code == "200") {
          self.download(response.data);
        } else {
          self.messages = response.data;
        }
      });
    },
    extractId: function(self) {
      let sp = self.split("/");
      return sp[sp.length - 1];
    },
    getFiles: function(id) {
      let self = this;
      this.azureAPI.getTranscriptionsFiles(id).then(response => {
        if (response.code == "200") {
          var fileId = self.extractId(response.data);
          this.azureAPI
            .getTranscriptionsJsonFileUrl(id, fileId)
            .then(response => {
              if (response.code == "200") {
                self.json_file = response.data;
                self.showModal = true;
              } else {
                alert(response.data);
              }
            });
        } else {
          alert(response.data);
        }
      });
    },
    datetimeString: function(ISOdate) {
      let date = new Date(ISOdate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let seconds = date.getSeconds();
      let minutes = date.getMinutes();
      let hours = date.getHours();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return (
        day +
        "/" +
        month +
        "/" +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
    upload: function() {
      let self = this;
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      let formData = new FormData();
      formData.append("_file", this.file);
      fsAPI.uploadFile(formData).then(response => {
        if (response.code == "200") {
          console.log(response.data);
          let params = {
            contentUrls: [response.data],
            properties: {
              diarizationEnabled: false,
              wordLevelTimestampsEnabled: true,
              punctuationMode: "Automatic",
              profanityFilterMode: self.profanity,
              email: self.email,
              timeToLive: "P7D"
            },
            locale: self.language,
            displayName: self.title
          };
          this.azureAPI.createTranscription(params).then(response => {
            if (response.code == "201") {
              self.getStatus();
            } else {
              alert(response.data);
            }
          });
          console.log(params);
        } else {
          alert(response.data);
        }
      });
    },
    getStatus: function() {
      this.azureAPI.getTranscriptionsList().then(response => {
        if (response.code == "200") {
          this.serverFiles = response.data;
          this.update_table = this.datetimeString(Date.now());
        } else {
          alert(response.data);
        }
      });
    }
  }
};
</script>

<style scoped>
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#messages {
  min-height: 100px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
