import { render, staticRenderFns } from "./FileTranscription.vue?vue&type=template&id=9414a556&scoped=true"
import script from "./FileTranscription.vue?vue&type=script&lang=js"
export * from "./FileTranscription.vue?vue&type=script&lang=js"
import style0 from "./FileTranscription.vue?vue&type=style&index=0&id=9414a556&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9414a556",
  null
  
)

export default component.exports